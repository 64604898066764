<template>
    <div>
        <div v-for="contact_person in row.item.contact_persons">
            <a href="#"
               @click.prevent="$root.$children[0].openModal('contact-person-form', {id:contact_person.id}, refreshTable)">
                {{ contact_person.vorname }} {{ contact_person.name }}
            </a>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
}
</script>